<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <div class="tabContainer" @click="getCurrentPage()">
        <ChatButton style="visibility: hidden;"/>
        <ion-tab-bar slot="bottom" class="tab-bar">
          <ion-tab-button class="tabBtn" tab="tab1" href="/tabs/tab1">
            <ion-icon :icon="currentPage == 1 ? ellipse : ellipseOutline" />
          </ion-tab-button>
            
          <ion-tab-button class="tabBtn" tab="tab2" href="/tabs/tab2">
            <ion-icon :icon="currentPage == 2 ? ellipse : ellipseOutline" />
          </ion-tab-button>
          
          <ion-tab-button class="tabBtn" tab="tab3" href="/tabs/map">
            <ion-icon :icon="currentPage == 3 ? ellipse : ellipseOutline" />
          </ion-tab-button>

          <ion-tab-button class="tabBtn" tab="browse" href="/tabs/browse">
            <ion-icon :icon="currentPage == 4 ? ellipse : ellipseOutline" />
          </ion-tab-button>

          <ion-tab-button class="tabBtn" tab="myAccount" href="/tabs/myAccount">
            <ion-icon :icon="currentPage == 5 ? ellipse : ellipseOutline" />
          </ion-tab-button>
        </ion-tab-bar>
        <ChatButton/>
      </div>
    </ion-tabs>
  </ion-page>
</template>

<script lang="js">
import { defineComponent } from 'vue';
import { IonTabBar, IonTabButton, IonTabs, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';
import { ellipse, square, triangle, ellipseOutline } from 'ionicons/icons';
import ChatButton from '@/components/ChatButton.vue';
// import { store } from '../store.js';

export default defineComponent({
  name: 'TabsPage',
  components: { IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet, ChatButton },
  data () {
    return {
      ellipse, 
      square, 
      triangle,
      ellipseOutline,
      currentPage: 3
    }
  },
  methods: {
    getCurrentPage() {
      this.currentPage = sessionStorage.getItem('currentPage');
    }
  }
});
</script>

<style scoped>
  .tab-bar {
    height: 30px;
    width: 60%;
    border-radius: 30px;
    background-color: rgba(51, 51, 255, 1);
    padding: 8px;
  }
  .tabContainer {
    height: 50px;
    width: 100vw;
    position: absolute;
    bottom: 30px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .tabBtn {
    background-color: unset;
  }
</style>
