<template>
  <button @click="move"> 
    <img src="../assets/chatBubble.svg" />
  </button>
</template>

<script>
export default {
  name: "chat-button",
  methods: {
    move () {
      this.$router.push('/chat')
    }
  }
}
</script>

<style scoped>
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: none;
    aspect-ratio: 1;
    width: 40px;
    height: 40px;
    padding: 5px;
    filter: opacity(95%);
    background-color: rgba(51, 51, 255, 1);;
  }
</style>