import { createRouter, createMemoryHistory } from '@ionic/vue-router';
import TabsPage from '../views/TabsPage.vue'
// import { store } from '../store.js';

const routes = [
  {
    path: '/',
    redirect: '/tabs/'
  },
  {
    path: '/signup',
    component : () => import('@/views/SignIn.vue')
  },
  {
    path: '/login',
    component : () => import('@/views/LogIn.vue')
  },
  {
    path: '/tabs/',
    component: TabsPage,
    // redirect: () => {
    //   // the function receives the target route as the argument
    //   // we return a redirect path/location here.
    //   return '/signup'
    // },
    children: [
      {
        path: '',
        redirect: '/tabs/map'
      },
      {
        path: 'tab1',
        name: 'tab1',
        component: () => import('@/views/Tab1Page.vue')
      },
      {
        path: 'tab2',
        name: 'tab2',
        component: () => import('@/views/Tab2Page.vue')
      },
      {
        path: 'map',
        name: 'map',
        component: () => import('@/views/Map.vue')
      },
      {
        path: 'browse',
        name: 'browse',
        component: () => import('@/views/GarageList.vue')
      },
      {
        path: 'myAccount',
        name: 'myAccount',
        component: () => import('@/views/MyAccount.vue')
      },
      {
        path: 'favoriesUser',
        name: 'favoriesUser',
        component: () => import('@/views/favoriesUser.vue')
      
      },
      {
        path: 'infosProfileUser',
        name: 'infosProfileUser',
        component: () => import('@/views/InfosProfileUser.vue')
      },
      {
        path: 'tab4',
        name: 'tab4',
        component: () => import('@/views/Tab4Page.vue')
      },
      {
        path: 'bookingHistory',
        component: () => import('@/views/bookingHistory.vue')
      
      },
      {
        path: 'advertisementUser',
        component: () => import('@/views/advertisementUser.vue')
      
      },
      {
        path: 'garageNotice',
        component: () => import('@/views/garageNotice.vue')
      
      }
    ]
  }
]


const router = createRouter({
  history: createMemoryHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from) => {
  // console.log(to, from);
  if(!localStorage.getItem('PAC-token') && to.path != '/signup' && to.path != '/login') {
    if(from.path != '/signup' || from.path != '/login') {
      // router.push('login');
      return '/login';
    }
    console.log('returning false');
    return '/signup';
  }
  console.log('returning true');
  const changedroute = routes[3].children.find(toPath => toPath.name == to.name);
  const index = routes[3].children.indexOf(changedroute);
  sessionStorage.setItem('currentPage', index)
  return true;
})

export default router
